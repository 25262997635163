<template>
    <v-hover v-slot:default="{hover}">
        <v-card class="card off mx-2 mb-1" :class="{ 'card_hover' : hover}"
                :elevation="hover ? 4 : 2" style="cursor: pointer;">
            <v-card-text class="card-state pa-1" :class="state">
                <!--HEADER-->
                <v-row no-gutters justify="center">
                    <v-col cols="5" class="font-weight-medium">
                        <span class="gold white--text px-3 rounded">{{ item.category }}</span>
                        <span class="tag-status white--text rounded px-3 ml-1" :class="state">{{ item.type }}</span>
                    </v-col>
                    <v-col cols="2" align="center">
                        <span
                            class="caption rounded px-2 info white--text font-weight-bold ml-2">{{ g_isDefined(item.sort) }}</span>
                    </v-col>
                </v-row>

                <!--DATA-->
                <v-row justify="center" align="center" class="" no-gutters>
                    <v-col cols="12">
                        <v-divider class="gold lighten-1"></v-divider>
                        <v-row align="center" no-gutters>
                            <span class="headline text-uppercase gold--text"
                                  style="font-size: 20px !important;">{{ g_isDefined(item.title) }}</span>
                        </v-row>
                    </v-col>

                    <!--IMAGE-->
                    <v-col cols="12" v-if="item.raw_data.type === 'Image'">
                        <img style="width: 25%;" :src="getImage" alt="" class="rounded">
                    </v-col>

                    <!--VIDEO-->
                    <v-col cols="12" v-else-if="item.raw_data.type === 'Video'">
                        <iframe class="content-card" :id="'video_'+item.id" type="text/html"
                                :src="'https://www.youtube.com/embed/' + item.file"
                                frameborder="0"/>
                    </v-col>

                    <!--ARTICLE-->
                    <v-col cols="12" v-if="item.raw_data.type === 'Article'"></v-col>

                    <!--KEYS-->
                    <v-col v-if="false" cols="12">
                        <v-row justify="center">
                            <v-col v-for="v in keys" :key="v.value" xl="0" md="0" style="width: calc(100% / 11)">
                                <v-row no-gutters>
                                    <span class="caption gold--text">{{ g_i18_keys(v.value) }}</span>
                                </v-row>

                                <v-row no-gutters>
                                    <span class="font-weight-bold">{{ item[v.value] }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col v-if="false" cols="12">
                        <span class="caption info--text">ITEM_DATA: {{ item }}</span>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: "ContentCard",
    props: {
        item: Object,
        keys: undefined,
        state: undefined,
    },

    computed: {
        getImage() {
            return process.env.VUE_APP_RGG_PORTAL_UX_API_URL + "/content/" + this.item.raw_data.category + "/" + this.item.id + "/data?jwt=" + this.$store.getters.getJWT + "&ts=" + this.$store.getters.getUpdateTimeStamp
        }
    }
}
</script>

<style>
iframe.content-card {
    width: 100%;
    max-width: 400px;
    height: 220px;
}
</style>